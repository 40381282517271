import { Title, Text, Image, Loader, Button } from "components/commons";
import { CircularProgress } from "@material-ui/core";
import React, { useMemo, useContext, useRef } from "react";
import { initialState as formState } from "./vip-redemption.state";
import { useForm, useModal, useApi, useMount, useRouter } from "hooks";
import styles from "./vip-redemption.module.scss";
import { ErrorCode, Earning, Path } from "enums";
import { capitalize, formatAmount } from "utils/text.utils";
import { VIPPoints, VIPPointsActive, CarWithLadyModal, CautionSign } from "images";
import locale from "localization";
import { StationContext } from "contexts";
import { validateRedemption, fetchRedemptionCards } from "apis";
import { NotifModal } from "components/modals";
import VipOTP from "./otp/vip-otp";

const VipRedemptionModule = (props) => {
  const { station } = useContext(StationContext);
  const { stationId } = station;
  const { location } = props;
  const claimVIPRewards = useModal();
  const redemptionOTPRef = useRef();
  const redemptionOTPModal = useModal();
  const errorModal = useModal();
  const history = useRouter();

  const vipRedemptionsSendOTP = useApi({
    api: validateRedemption,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const {
    result: vipRedemptionCardsResult,
    request: vipRedemptionCardsRequest,
    loading: vipRedemptionCardsLoading,
  } = useApi({
    api: fetchRedemptionCards,
    handleOwnError: {
      badrequest: true,
    },
    modalError: true,
  });

  const loadingVIPRedemption = vipRedemptionsSendOTP.loading;

  const form = useMemo(() => {
    let initialState = {};
    return formState(initialState);
  }, []);

  useMount(() => {
    if (location?.state?.user?.userId) {
      vipRedemptionCardsRequest({
        stationId,
        userId: location?.state?.user?.userId,
      });
    }
  });

  const { fields, modifyField, clearForm } = useForm({
    initialState: form,
  });

  const cardDetails = {
    vipDetails: location?.state ? { ...location.state } : {},
  };

  const loadingValidateVIP = false;

  const pointsList = useMemo(() => {
    if (vipRedemptionCardsResult?.data) {
      return vipRedemptionCardsResult?.data?.options;
    }

    return [];
  }, [vipRedemptionCardsResult?.data]);

  const handleSelectCard = (points) => {
    let modalContent = {
      image: CarWithLadyModal,
      title: locale.claimVIPRewardsQuestionMark,
      content: locale.claimRewardsDescription,
      contentHighlight: [
        formatAmount(points, "", 0),
        <b>{formatAmount(points)}</b>,
        <b>{capitalize(cardDetails?.vipDetails?.vipFullName)}</b>,
      ],
    };
    modifyField(fields?.points?.name, {
      value: points,
    });

    claimVIPRewards.show({
      ...modalContent,
      primary: {
        text: locale.yesProceed,
        onClick: async () => {
          try {
            const res = await vipRedemptionsSendOTP.request({
              points,
              stationId,
              type: "station",
              userId: cardDetails?.vipDetails?.user?.userId,
              mobileNumber: cardDetails?.vipDetails?.mobileNumber,
            });
            if (res) {
              redemptionOTPRef.current.handleStartTimer();
              redemptionOTPModal.show({
                actionText: locale.proceed,
                initialState: {
                  otp: {
                    value: "",
                  },
                },
                points,
                otherData: {
                  ...location?.state,
                  userId: location?.state?.user?.userId ?? "",
                  details: cardDetails?.vipDetails,
                },
                stationId,
                vipRedemptionsSendOTP,
                clearFormVIPRedemption: clearForm,
                resendOTPVIP: vipRedemptionsSendOTP,
              });
              claimVIPRewards.close();
            }
          } catch (error) {
            switch (error?.data?.errorCode) {
              case ErrorCode.OTPOngoing:
                redemptionOTPModal.show({
                  actionText: locale.proceed,
                  initialState: {
                    otp: {
                      value: "",
                    },
                  },
                  points,
                  otherData: {
                    ...location?.state,
                    userId: location?.state?.user?.userId ?? "",
                    details: cardDetails?.vipDetails,
                  },
                  stationId,
                  vipRedemptionsSendOTP,
                  clearFormVIPRedemption: clearForm,
                  resendOTPVIP: vipRedemptionsSendOTP,
                });
                break;
              case ErrorCode.InsufficientPointsBalance:
                claimVIPRewards.close();

                errorModal.show({
                  image: CautionSign,
                  title: locale.ohSnap,
                  content: error?.data?.message,
                  primary: {
                    onClick: async () => {
                      modifyField(fields?.points?.name, {
                        value: "",
                      });
                      errorModal.close();
                    },
                  },
                  secondary: {
                    text: locale.cancel,
                    disabled: loadingVIPRedemption,
                    onClick: async () => {
                      modifyField(fields?.points?.name, {
                        value: "",
                      });
                      errorModal.close();
                    },
                  },
                });
                break;
              case ErrorCode.ConversionReachedMaximumLimit:
                claimVIPRewards.close();

                errorModal.show({
                  image: CautionSign,
                  title: locale.ohSnap,
                  content: error?.data?.message,
                  primary: {
                    onClick: async () => {
                      modifyField(fields?.points?.name, {
                        value: "",
                      });
                      errorModal.close();
                    },
                  },
                  secondary: {
                    text: locale.cancel,
                    disabled: loadingVIPRedemption,
                    onClick: async () => {
                      modifyField(fields?.points?.name, {
                        value: "",
                      });
                      errorModal.close();
                    },
                  },
                });
                break;
              default:
                redemptionOTPModal.close();
            }
          }
        },
      },
      secondary: {
        text: locale.cancel,
        disabled: loadingVIPRedemption,
        onClick: async () => {
          modifyField(fields?.points?.name, {
            value: "",
          });
          claimVIPRewards.close();
        },
      },
    });
  };

  return (
    <div className={styles.container}>
      <VipOTP {...redemptionOTPModal} ref={redemptionOTPRef} />
      <NotifModal {...claimVIPRewards} loading={loadingVIPRedemption} />
      <NotifModal {...errorModal} loading={loadingVIPRedemption} />
      <div className={styles.leftCardsContainer}>
        <div className={styles.intro}>
          <div>
            <Title className={styles.title}>{locale.claimVIPRewards}</Title>
            <Text style={{ marginTop: "10px" }} strong>
              {locale.selectARewardToRedeem}
            </Text>
          </div>
          <div className={styles.vipAccount}>
            {loadingValidateVIP ? (
              <div className={styles.circular}>
                <CircularProgress className={styles.circular} />
              </div>
            ) : (
              <div className={styles.viplinkContent}>
                <div className={styles.vipAccountDetails}>
                  <Text
                    className={cardDetails?.vipDetails ? styles.titleActive : styles.titleInActive}
                  >
                    {cardDetails?.vipDetails ? cardDetails?.vipDetails?.vipFullName : locale.name}
                  </Text>
                  <Text
                    className={
                      cardDetails?.vipDetails
                        ? styles.vipAccountWithData
                        : styles.vipAccountWithoutData
                    }
                  >
                    {cardDetails?.vipDetails
                      ? cardDetails?.vipDetails?.accountType
                      : locale.vipAccountType}
                  </Text>
                  <Text
                    className={
                      cardDetails?.vipDetails
                        ? styles.vipAccountWithData
                        : styles.vipAccountWithoutData
                    }
                  >
                    {cardDetails?.vipDetails &&
                    cardDetails?.vipDetails?.hasOwnProperty("isMobileNumber") &&
                    (cardDetails?.vipDetails?.user?.vipType === "biz" ||
                      cardDetails?.vipDetails?.user?.vipType === "public")
                      ? ""
                      : cardDetails?.vipDetails?.cardNumber || locale.cardNumberVIP}
                  </Text>
                  <Text
                    className={
                      cardDetails?.vipDetails
                        ? styles.vipAccountWithData
                        : styles.vipAccountWithoutData
                    }
                  >
                    {cardDetails?.vipDetails
                      ? cardDetails?.vipDetails?.mobileNumber
                      : locale.cpNumber}
                  </Text>
                </div>
                <div className={styles.vipAccountPointsContainer}>
                  <div className={styles.vipAccountPoints}>
                    <div className={styles.imageWrapper}>
                      <Image
                        src={cardDetails?.vipDetails ? VIPPointsActive : VIPPoints}
                        className={styles.imgPoint}
                      />
                    </div>
                    <div className={styles.textWrapper}>
                      <p
                        className={
                          cardDetails?.vipDetails
                            ? styles.textPointsActive
                            : styles.textPointsInActive
                        }
                      >
                        <Text
                          className={
                            cardDetails?.vipDetails ? styles.pointsActive : styles.pointsInActive
                          }
                        >
                          {cardDetails?.vipDetails ? cardDetails?.vipDetails?.points : 0}
                        </Text>
                        <Text
                          className={
                            cardDetails?.vipDetails
                              ? styles.pointsActiveText
                              : styles.pointsInActiveText
                          }
                        >
                          {locale.points}
                        </Text>
                      </p>
                    </div>
                  </div>
                  <div className={styles.vipAccountViewEarningHistory}>
                    <Button
                      className={
                        cardDetails?.vipDetails && cardDetails?.vipDetails?.isRegistered
                          ? styles.redemptionButtonWithDataViewEarning
                          : styles.redemptionButtonViewEarningWithoutData
                      }
                      // disabled={disabledPayWithPointsButton}
                      onClick={() => {
                        history.push({
                          pathname: Path.VIPEarningHistoryRedemption,
                          state: {
                            ...cardDetails?.vipDetails,
                            awardType: Earning.PayWithPoints,
                          },
                        });
                      }}
                    >
                      {locale.viewEarningHistory}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.pointsConversionContainer}>
          <Loader open={vipRedemptionCardsLoading} />
          {pointsList.map((item) => {
            return (
              <div
                className={
                  item.enabled ? styles.cardRedemptionActive : styles.cardRedemptionInActive
                }
                onClick={() => item.enabled && handleSelectCard(item.points)}
              >
                <div className={styles.cardLeft}>
                  <div className={styles.inner}>
                    <div className={styles.title}>{formatAmount(item.points)}</div>
                    <div className={styles.cash}>Cash</div>
                  </div>
                </div>
                <div className={styles.cardRight}>
                  <div className={styles.title}>{locale.pointsRedemption}</div>
                  <div className={styles.vipAccountPointsContainer}>
                    <div className={styles.vipAccountPoints}>
                      <div className={styles.textWrapper}>
                        <p
                          className={
                            item.enabled ? styles.textPointsActive : styles.textPointsInActive
                          }
                        >
                          <Text
                            className={item.enabled ? styles.pointsActive : styles.pointsInActive}
                          >
                            {formatAmount(item.points, "", 0)}
                          </Text>
                          <Text
                            className={
                              item.enabled ? styles.pointsActiveText : styles.pointsInActiveText
                            }
                          >
                            {locale.pts}
                          </Text>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VipRedemptionModule;
