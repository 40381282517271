import { RewardsRedeemed } from "enums";

export const redeemHistoryState = () => ({
  startDate: null,
  endDate: null,
  page: 1,
  perPage: 10,
  searchKey: "",
  rewardsRedeemed: {
    isSelectedAll: true,
    value: [RewardsRedeemed.Cash],
  },
});
