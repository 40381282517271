import { Image, Modal, Text, Title, Button } from "components/commons";
import React from "react";
import styles from "./success-modal.module.scss";
import locale from "localization";
import { useRouter } from "hooks";
import { CarFormSuccess } from "images";
import { Path } from "enums";

const SuccessModalForm = ({
  title,
  content = "",
  image = CarFormSuccess,
  primary,
  secondary,
  contentHighlight = [],
  transactionData,
  otherData,
  ...state
}) => {
  const history = useRouter();
  const vipDetails = otherData?.isMobile
    ? {
        mobileNumber: otherData?.mobileNumber?.replace("+63", "") ?? "",
      }
    : { cardNumber: otherData?.cardNumber ?? "" };

  return (
    <Modal {...state}>
      <div className={styles.container}>
        <Image src={image} className={styles.image} />
        <Title small className={styles.title}>
          {title}
        </Title>
        <Text className={styles.content}>
          <locale.Populate text={content} items={contentHighlight} />
        </Text>
        <div className={styles.table}>
          <div className={styles.row}>
            <label>{locale.newPointBalance}</label>
            <label>
              {transactionData?.newPointsBalance ?? 0} {locale.points}
            </label>
          </div>
        </div>
      </div>
      <div className={styles.rowDoneButton}>
        <Button
          primary
          className={styles.doneButton}
          onClick={() => {
            state.close();
            history.push({
              pathname: Path.VIPAccount,
              state: { ...vipDetails },
            });
          }}
        >
          {locale.done}
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModalForm;
