import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Title } from "components/commons";
import styles from "./back-link.module.scss";
import { NavLink } from "react-router-dom";

const BackLinkV2 = ({ text, path, onBackClick }) => {
  const handleClick = (event) => {
    if (onBackClick) {
      event.preventDefault(); // Prevent default navigation
      onBackClick(); // Execute the custom back navigation logic
    }
  };

  return onBackClick ? (
    <div className={styles.backlink} onClick={handleClick} role="button" tabIndex={0}>
      <span className={styles.backlinkContent}>
        <ChevronLeftIcon className={styles.icon} />
        <Title className={styles.text}>{text}</Title>
      </span>
    </div>
  ) : (
    <NavLink to={path}>
      <div className={styles.backlink}>
        <span className={styles.backlinkContent}>
          <ChevronLeftIcon className={styles.icon} />
          <Title className={styles.text}>{text}</Title>
        </span>
      </div>
    </NavLink>
  );
};

export default BackLinkV2;
