import locale from "localization/en";

export const columns = [
  { key: "businessName", text: locale.businessName, width: "250px" },
  // { key: "driversCreditLimit", text: locale.creditLimit, width: "200px" },
  { key: "fleetAvailableCredit", text: locale.fleetAvailableCredit, width: "200px" },
  { key: "latestBillingStatement", text: locale.latestBillingStatement, width: "200px" },
  { key: "dueDate", text: locale.dueDate, width: "200px" },
  { key: "amountPaid", text: locale.amountPaid, width: "200px" },
  // { key: "transact", text: locale.transact, width: "200px" },
  { key: "statementOfAccount", text: locale.statementOfAccount, width: "100px" },
];
