export const locale = {
  viewRedemptions: "View redemptions",
  save: "Save",
  editDetails: "Edit details",
  deactivate: "Deactivate",
  activate: "Activate",
  vehicle: "Vehicle",
  drumContainer: "Drum/Container",
  manageFleetVehicles: "Manage fleet vehicles",
  manageYourVehicle: "Manage your vehicles and keep their information up to date.",
  addVehicles: "Add Vehicles",
  addVehicle: "Add Vehicle",
  add: "Add",
  sorryNoResultFound: "Sorry, no results found",
  weCouldNotFindAMatchForKey: "We could not find a match for “{0}“.",
  pleaseTryADifferentOne: "Please try a different one.",
  vehicleSuccessfullyRegistered: "Vehicle successfully registered!",
  vehicleInformationSuccessfullyEdited: "Vehicle information successfully edited!",
  youHaveSuccessfullyRegisteredPlateNumber:
    "You have successfully registered {0} to the vehicles’ list.",
  youHaveEditedVehicleDetails: "You have successfully changed the information of {0}.",
  registerNewVehicle: "Register New Vehicle",
  vehicleIdAlreadyExists: "This Vehicle ID already exists.",
  cancel: "Cancel",
  manageFleetDrivers: "Manage fleet drivers",
  manageYourDrivers: "Manage your drivers and keep their information up to date.",
  addDriver: "Add Driver",
  driverSuccessRegistered: "Driver successfully registered!",
  driverSuccessEdited: "Driver information successfully edited!",
  successfullyRegisteredDriverName:
    "You have successfully registered {0} {1} to the drivers' list.",
  successfullyChangeInfoOfDriverName: "You have successfully changed the information of {0} {1}",
  registerNewDriver: "Register New Driver",
  driverLicenseIdExists: "This Driver ID already exists.",
  enterFirstNameHere: "Enter first name here",
  enterLastNameHere: "Enter last name here",
  pleaseEnterAValidMobileNumber: "Please enter a valid mobile number.",
  plateNumber: "Plate Number",
  vehicleModelType: "Vehicle Model Type",
  remarksOptions: "Remarks (optional)",
  remarks: "Remarks",
  enterRemarksHere: "Enter remarks here",
  plateNumberPlaceholder: "XXXXXX (don't include spaces or dash)",
  youAreAboutToDeactivateVehicle:
    "You are about to deactivate vehicle {0}. This will temporarily prevent vehicle {1} from doing any transaction. Do you want to proceed?",
  youAreAboutToReactivateVehicle:
    "You are about to reactivate vehicle {0}. This will enable vehicle {1} to do transactions again. Do you want to proceed?",
  askDeactivateVehicle: "Deactivate vehicle?",
  askReactivateVehicle: "Reactivate vehicle?",
  yesDeactivateIt: "Yes, Deactivate it",
  yesReactivateIt: "Yes, Reactivate it",
  youAreAboutDeactivateDriver:
    "You are about to deactivate {0}’s account. This will temporarily prevent {1} from doing any transaction. Do you want to proceed?",
  youAreAboutReactivateDriver:
    "You are about to reactivate {0}’s account. This will enable {1} to do transactions again. Do you want to proceed?",
  askDeactivateDriver: "Deactivate driver?",
  askReactivateDriver: "Reactivate driver?",
  firstName: "First Name",
  lastName: "Last Name",
  pleaseEnterAValidLicensedId: "Please enter a valid driver license ID.",
  qrCode: "QR Code",
  generateQrCode: "Generate QR Code",
  generic: "Generic",
  templated: "Templated",
  generateAndPrintQrCode: "Generate and Print QR Code",
  mobileNumber: "Mobile Number",
  driverId: "Driver ID",
  all: "All",
  nameMobileNumberDriverId: "Name, Mobile Number, Driver ID",
  name: "Name",
  status: "Status",
  model: "Model",
  emailAddress: "Email Address",
  password: "Password",
  rememberMe: "Remember Me",
  logIn: "Log In",
  theEnteredEmailPassIncorrect: "The entered email or password is incorrect",
  fleetPortal: "Fleet Portal",
  pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
  cashInHistory: "Cash in History",
  cashInHistoryCapitalize: "Cash In History",
  referenceId: "Reference ID",
  referenceNumber: "Reference Number",
  referenceIdAndNumber: "Reference ID, Reference Number",
  amount: "Amount",
  createdDate: "Created Date",
  fullfilledDate: "Fulfilled Date",
  cashInChannel: "Cash-In Channel",
  active: "Active",
  inactive: "Inactive",
  completed: "Completed",
  pending: "Pending",
  failed: "Failed",
  expired: "Expired",
  gasName: "Gas {0}",
  diesel: "Diesel",
  manualPaymaya: "Manual - Paymaya",
  manualBankDeposit: "Manual - Bank Deposit",
  purchasedDate: "Purchased Date",
  product: "Product",
  creditPrice: "Credit Price (₱/L)",
  volume: "Volume",
  totalCreditAmount: "Total Credit Amount",
  buyFuelHistory: "Buy fuel history",
  buyFuel: "Buy fuel",
  buyFuelCapitalize: "Buy Fuel",
  pleaseSelectProductYouWish: "Please select product you wish to purchase.",
  cashFlow: "Cash Flow",
  cashIn: "Cash In",
  fuel: "Fuel",
  generalFuelCodes: "Generate Fuel Codes",
  myFuelCodes: "My Fuel Codes",
  fleetActivity: "Fleet Activity",
  redemptions: "Redemptions",
  drivers: "Drivers",
  vehicles: "Vehicles",
  onlyAlphaNumericAllowed: "Only Alphanumeric characters are allowed.",
  onlyAlphaNumericAllowedAndDash:
    "Only Alphanumeric characters and the dash symbol ( - ) are allowed.",
  onlyAlphaNumericAllowedAndSpecial:
    "Only Alphanumeric characters and the following special symbol ( - , . , spaces ) are allowed.",
  mobileNumberIsInvalid: "This mobile number is invalid.",
  driverLicenseIdIsInvalid: "This driver license ID is invalid.",
  date: "Date",
  to: "to",
  gotIt: "Got It",
  onlyNumericAreAllowed: "Only numeric characters are allowed.",
  ohSnap: "Oh snap!",
  somethingWentWrongPleaseTryAgain: "Something went wrong. Please try again.",
  tryAgain: "Try Again",
  generateFuelCode: "Generate fuel code",
  balance: "Balance",
  productType: "Product Type",
  productTypeCapitalize: "Product Type",
  volumeRedeemLimit: "Volume Redeem Limit",
  eligiblePeriod: "Eligible Period",
  eligibleDrivers: "Eligible Drivers",
  eligibleVehicles: "Eligible Vehicles",
  continue: "Continue",
  searchOrSelect: "Search or select",
  kindlyReviewDetails: "Kindly review the below details before generating the fuel code.",
  generateFuelCodeCapitalize: "Generate Fuel Code",
  successfullyGeneratedFuelCode: "You Successfully Generated Fuel Code!",
  shareAndStartUsingThisCode: "Share and start using this code to redeem fuels at the stations.",
  goToMyFuelCodes: "Go to My Fuel Codes",
  generateAnotherCode: "Generate Another Code",
  maximumAllowedRedeemLiters: "Maximum allowed redeem limit is {0} liters.",
  minimumAllowedLitre: "Minimum allowed redeem limit is 1 liter.",
  priceHistory: "Price History",
  effectiveDate: "Effective Date",
  fuelPrice: "Fuel Price",
  priceMovement: "Price Movement",
  discount: "Discount",
  creditPriceHistory: "Credit Price",
  myFuelCodesTitle: "My fuel codes",
  createFuelCodes: "Create fuel codes and keep track of their expiry dates.",
  dateGenerated: "Date Generated",
  fuelCode: "Fuel code",
  codeStartDate: "Code Start Date",
  codeEndDate: "Code End Date",
  volumeLimit: "Volume Limit",
  volumeRedeemed: "Volume Redeemed",
  useLimit: "Use Limit",
  useCount: "Use Count",
  eligibleDriversAndVehicles: "Eligible Drivers and vehicles",
  scheduled: "Scheduled",
  deactivated: "Deactivated",
  actions: "Actions",
  action: "Action",
  viewDetails: "View Details",
  stepOneSelectFuel: "Step 1: Select Fuel",
  viewPriceHistory: "View Price History",
  liters: "Liters",
  liter: "Liter",
  peso: "Peso",
  stepTwoEnterAmount: "Step 2: Enter Amount",
  buyFuelHistoryCapitalize: "Buy Fuel History",
  totalLitersIsAmount: "Total liters is {0}.",
  totalAmountIs: "Total amount is {0}.",
  minimumOrderIs1Liter: "Minimum order is 1 liter.",
  askConfirmWithPayment: "Proceed with payment?",
  nLiter: "{0} Liter",
  nLiters: "{0} Liters",
  ofText: "of {0}",
  forText: "for {0}",
  isAboutToBePurchased: "is about to be purchased.",
  kindlyReviewTheDetails: "Kindly review the details before proceeding.",
  productPrice: "Product Price",
  total: "Total",
  totalCaps: "TOTAL",
  pay: "Pay",
  success: "Success",
  orNumber: "OR Number",
  poNumber: "Purchase Order No.",
  paymentConfirmed: "Your payment has been confirmed.",
  goToBuyFuelHistory: "Go to Buy Fuel History",
  askDeactivateFuelCode: "Deactivate Fuel Code?",
  youAreAboutDeactivateFuelCode:
    "You are about to deactivate fuel code {0}. This will disable the said fuel code forever and will not be usable anymore. Do you want to proceed?",
  buyFuelFailed: "Buy Fuel transaction failed",
  errorProcessingPayment: "There was an error processing your payment. Please try again.",
  buyFuelAgain: "Buy Fuel Again",
  oops: "Oops! Something went wrong",
  pleaseRefreshYourBrowser: "Please refresh your browser",
  backToHomePage: "Back to Home Page",
  okayGotIt: "Okay, got it!",
  viewTrackRedemptions: "View, track redemption activities of your fleet.",
  redemptionDate: "Redemption Date",
  redemptionStation: "Redemption Station",
  aveCreditPrice: "Ave. Credit Price",
  driverIdPlateNumberFuelCode: "Driver ID, Plate No., Fuel Code",
  literVolume: "Liter Volume",
  welcome: "Welcome,",
  yourFleetConsumptionForTheMonth: "Your Fleet Consumption for the Month",
  activateAccount: "Activate Account",
  basicInformation: "Basic Information",
  confirmPassword: "Confirm Password",
  setPassword: "Set Password",
  use8CharWithMix:
    "Use 8 or more characters with a mix of uppercase and lowercase letters and numbers/symbols.",
  passwordsDoNotMatch: "Passwords do not match",
  passwordDoesNotMeetRequirements: "Password does not meet requirements",
  goToLogin: "Go to Login",
  yourAccountHasBeenActivated:
    "Your account has been successfully activated. You can now use your password to log in to your account.",
  expiredUsedActivationLinkToken:
    "The activation link has expired. Please contact your administrator to request for a new activation link.",
  payment: "Payment",
  stationPortal: "Station Portal",
  stationCode: "Station Code",
  stationName: "Station Name",
  validatePaymentCapitalize: "Validate Payment",
  paymentHistoryCapitalize: "Payment History",
  pleaseEnterFuelCode: "Please enter fuel code to validate payment eligibility.",
  paymentHistory: "Payment History",
  searchIdBusinessPlaceOrFuelCodeOrRedemptionId:
    "Search Business Name, Driver ID, Plate Number, Fuel Code or OR Number, Redemption ID",
  validateFuelCode: "Validate fuel code",
  validateFuelCodeAndValume: "Validate fuel code and volume to dispense",
  customerDetails: "Customer details",
  businessName: "Business Name",
  eligibleDriver: "Eligible Driver",
  eligibleVehicle: "Eligible Vehicle",
  vehicleModel: "Vehicle Model",
  averageCreditPrice: "Ave. Credit Price",
  averageFullCreditPrice: "Average Credit Price",
  transactionId: "Transaction ID",
  fuelCodeCapitalize: "Fuel Code",
  doesNotExistFuelCode: "Sorry, this code does not exist.",
  fuelCodeIsInactive: "Sorry, this code is still inactive.",
  expiredFuelCode: "Sorry, this code has expired/already been used.",
  deactivatedFuelCode: "Sorry, this code has been deactivated.",
  paymentDetails: "Payment details",
  volumeToBeRedeemed: "Volume to be redeemed",
  volumeDispensed: "Volume dispensed",
  dispensedTo: "Dispensed To",
  pleaseWaitForGasUpToFinish: "(please wait for gas-up to finish)",
  fleetProductBalance: "Fleet Product Balance",
  fuelCodeBalance: "Fuel Code Balance",
  isAboutToBeRedeemed: "is about to be redeemed.",
  done: "Done",
  paymentSuccess: "Payment Success!",
  hasBeenSuccessfullyRedeemed: "has been successfully redeemed!",
  paymentFailed: "Payment failed!",
  anErrorEncountered: "An error was encountered while processing your payment.",
  pleaseTryAgain: "Please try again or contact customer support for assistance.",
  unableToProcessPayment: "Unable To Process Payment",
  pleaseContactYourFleetAdministrator:
    "Please contact your fleet administrator to replenish the fleet product balance.",
  weCouldNotFindResults: "We could not find results for your query.",
  insufficientLiterBalance: "Insufficient Liter Balance",
  maximumAllowedRedemption: "Maximum volume for redemption is {0}",
  minimumAllowedRedemption: "Minimum allowed for redemption is {0}",
  minimumAllowedRedemption1Liter: "Minimum allowed redemption is 1 liter.",
  none: "None",
  testingMode: "Testing Mode",
  any: "Any",
  forgotPassword: "Forgot password?",
  passwordReset: "Password Reset",
  pleaseEnterYourEmailAddress: "Please enter your email address",
  pleaseEnterNewPassword: "Please enter a new password",
  confirmNewPassword: "Confirm new password",
  goToLoginPage: "Go to Login page",
  passwordInstructionHaveSent: "Thanks! Password reset instructions have been sent to {0}.",
  passwordHasBeenSuccessfullyChanged:
    "The password for username {0} has been successfully changed.",
  passwordChanged: "Password changed!",
  sorryWeDontRecognizeThisAccount: "Sorry, we don’t recognize this account",
  passwordRecoveryLinkInvalid:
    "This password recovery link has already been used or is no longer valid.",
  thisEmailHasNotYetBeenActivated: "This email has not yet been activated.",
  forBusiness: "For Business",
  forConsumer: "For Consumer",
  paymentHistoryTitle: "Payment History - {0}",
  business: "Business",
  consumer: "Consumer",
  redemptionId: "Redemption ID",
  applyFilters: "Apply Filters",
  clearAll: "Clear All",
  filters: "Filters",
  availableProducts: "Available Products",
  thisCodeCanOnlyBeRedeemdedAt: "This code can only be redeemed at these {0}.",
  stations: "stations",
  redemptionStations: "Redemption Stations",
  voided: "Voided",
  voidTransaction: "Void Transaction",
  voidingThisTransaction:
    "Voiding this transaction will return {0} to the account’s liter balance and will be excluded in the next settlement cycle. Input the reason for voiding below to proceed",
  voidingThisTransactionSettlementProcessing:
    "Voiding this transaction will return {0} to the account’s liter balance. This transaction is already in the process of completing settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed",
  voidingThisTransactionSettlementProcessed:
    "Voiding this transaction will return {0} to the account’s liter balance. This transaction has already been processed for settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed.",
  transactionVoided: "Transaction successfully voided",
  thisTransactionHasBeenVoided:
    "This transaction has been voided and {0} has been successfully returned to {1}",
  void: "Void",
  editRemarks: "Edit Remarks",
  reason: "Reason",
  forProcessing: "For Processing",
  forSettlement: "For Settlement",
  settled: "Settled",
  processed: "Processed",
  cancelled: "Cancelled",
  settlements: "Settlements",
  settlementHistory: "Settlement History",
  viewTrackSettlement: "View and track settlements",
  cancelSettlement: "Cancel Settlement",
  cancelSettlementMessage: "You are about to cancel settlement ID {0}. Do you want to proceed?",
  back: "Back",
  yesCancelSettlement: "Yes, Cancel Settlement",
  settlementDetails: "Settlement Details",
  periodOfStatement: "Period of Statement",
  settelmentCancelSuccess: "Settlement successfully cancelled",
  cancelSettlementSuccessMessage: "You have successfully cancelled the settlement ID {0}",
  report: "Report",
  editSettlementStatus: `Are you sure you want to update Dealer settlement payout status to "{0}"? You can't undo this action`,
  editSettlement: "Are you sure you want to save all changes to this Settlement?",
  updateStatus: "Update Status",
  processSettlement: "Process Settlement",
  processSettlementQuestion: "Process settlement?",
  processSettlementMessage:
    "You are about to process settlement for {0} from {1} to {2} Do you want to proceed?",
  successProcessSettlementMessage:
    "You can now proceed with the payment add update the settlement status in the payment history afterwards",
  goBackSettlements: "Go back to Settlements",
  goSettlementHistory: "Go to Settlement History",
  dateToBeSettled: "Date to be Settled",
  generateReport: "Generate Report",
  noDataYet: "No data yet",
  generateReportError: "Generate report error",
  noRedemptionToSettled: "There are no redemptions to be settled",
  stationBusinessNameRedemptionStation: "Settlement ID",
  view: "View",
  dealerSettlementPayoutStatus: "Dealer Settlement Payout Status",
  discountVoucherStatus: "Discount Voucher Status",
  stationBusinessName: "Station Business Name",
  totalLiters: "Total Liter Redemption",
  totalDealerSettlementPayout: "Total Dealer Settlement Payout",
  totalDiscountVoucher: "Total Discount Voucher to Dealer",
  totalSettlementWithSeaoil: "Total Settlement with SEAOIL",
  dateProcessed: "Date Processed",
  settlementId: " Settlement ID",
  export: "Export",
  settlementStatus: "Settlement Status",
  effectiveDiscountPerLiter: "Effective Discount Per Liter",
  inbox: "Inbox",
  search: "Search",
  selectAnItemToRead: "Select an item to read",
  noItemSelectedPleaseSelectAnItem: "No item selected. Please select an item in the list.",
  weCouldNotFindAMatchForYou:
    "We could not find a match for your search criteria. Please try a different one.",
  youCanNowVoidTransactions: "You can now void PriceLOCQ transactions!",
  withNewVoidTransactionFeature:
    "With the new Void Transaction feature, you can now refund your customers easily if needed. Click learn more to see how it works",
  learnMore: "Learn More",
  maybeLater: "Maybe Later",
  asPdf: "Export PDF",
  asExcel: "Export Excel",
  reportGenerated: "Report is being generated",
  reportMessage:
    "Export file will be sent to {0} once it is completely generated. If you did not receive any email, kindly message us at {1}.",
  exclamatedSuccess: "Success!",
  plcLocqpay: "PLC LOCQPay for Fuel",
  viewAndTrackScanToPayViaLocqpay: "View and track Scan-to-Pay via LOCQPay transactions for fuel.",
  locqpay: "LOCQPay for Fuel",
  locqpayForFuelId: "LOCQPay for Fuel ID",
  dateOfTransaction: "Date of Transaction",
  paymentMethod: "Payment Method",
  paymentAmount: "Payment Amount",
  transactionDate: "Transaction Date",
  downloadSingle: "Download",
  download: "Downloads",
  reportType: "Report Type",
  deleteReport: "Delete report?",
  deleteReportQuestion: "Are you sure you want to delete {0}? You can't undo this action.",
  dateOfGeneration: "Date of Generation",
  logout: "Logout",
  weCouldNotFindAMatchForSearchCriteria: "We could not find a match for your search criteria",
  totalMDRFee: "Total MDR Fee",
  totalLocqpay: "Total LOCQPay for Fuel Amount",
  monthAndYear: "Month and Year",
  totalPremium: "Total Premium Payout",
  month: "Month",
  year: "Year",
  premiumPayment: "Premium Payment",
  monthlyPremiumPayment: "Monthly Premium Payout for PriceLOCQ Redemptions",
  monthlyPremiumPaymentDetails: "Monthly Premium Payout Details",
  viewAndTrackMonthlyPremium: "View and track Monthly",
  mischargeWrongInputByUser: "Mischarged: Wrong Volume Input by User",
  mischargeWrongVolumeInput: "Mischarged: Wrong Volume Input (Tank Full before reaching Amount)",
  others: "Others",
  userAccess: "User Access",
  accessAuthorization: "Access Authorization",
  monitorUserAccessToStationPortal: "Monitor user access to Station Portal",
  addUser: "Add User",
  reactivate: "Reactivate",
  role: "Role",
  admin: "Admin",
  accounting: "Accounting",
  cashier: "Station Cashier",
  lubeserv: "Lubeserv",
  anAccountActivationInstructionHasBeenSent:
    "An account activation instruction has been sent to the email address you've provided.",
  goToListOfUsers: "Go to List of Users",
  addAnotherUser: "Add Another User",
  saveDetailsAndSendEmail: "Save details and send email?",
  saveAndSendEmail: "Save and Send Email",
  uponClickingSaveAndSendEmail:
    'Upon clicking "Save and send email", an account activation instruction will be sent to {0} and the user will have the {1} access. Do you want to proceed?',
  accessType: "Access Type",
  yesChangeRole: "Yes, Change the Role",
  youAreAboutChangeRoleUser: "You are about to change the role of the {0}'s account.",
  thisWillChangeAccessUser: "This will change the access of {0} . Do you want to proceed?",
  michargedDidNotIntendtoPayViaLocqpay: "Mischarged: Did not intend to pay via LOCQPay",
  michargedWrongAmountInput: "Mischarged: Wrong Amount Input",
  voidingThisPayment:
    "Voiding this transaction will return {0} to the account's LOCQPay balance and deduct the previously rewarded {1} rebate from the transaction. Input the reason below to proceed.",
  thisPaymentHasBeenVoided:
    "This transaction has been voided and {0} has been successfully returned to account's LOCQPay balance",
  reasonRemarks: "Reason/Remarks",
  actionsHistory: "Actions History",
  lubeServOnSite: "Lubeserv on Site",
  otherServices: "Other Services",
  viewAndTrackLubeServOnSiteBookingsTransaction: "View and track booking activities.",
  address: "Address",
  bookingID: "Booking ID",
  preferredDate: "Preferred Date",
  preferredTime: "Preferred Time",
  oilChangePackage: "Oil Change Package",
  vehicleYear: "Vehicle Year",
  dateCreated: "Date Created",
  lubeServSite: "LubeServ Site",
  modifiedBy: "Modified By",
  remove: "Remove",
  saveChanges: "Save Changes",
  saveChangesQuestion: "Save changes?",
  youAreAboutRemoveUser: "{0} will lose access to Station Portal. Do you want to proceed?",
  yesRemoveUser: "Yes, Remove",
  successfullyRemoveUser: "{0} have been successfully removed.",
  successfullyUpdateUser: "{0} Details have been successfully updated.",
  removedUserQuestion: "Remove user?",
  emailAddressExist: "Email address already exist",
  dateCreatedRange: "Date Created Range",
  viewReceiptGuide: "View Receipt Guide",
  receiptGuide: "Receipt Guide",
  dateTime: "Date/Time",
  soldTo: "Sold To",
  plateNo: "Plate No.",
  driversLicenseId: "Driver's License ID",
  priceLOCQUnitPrice: "PriceLOCQ Unit Price",
  vatTableSales: "VATable Sales",
  vatExemptSales: "VAT-Exempt Sales",
  vatZeroRatedSales: "VAT-Zero Rated Sales",
  vatAmount: "VAT Amount",
  totalAmountDue: "TOTAL AMOUNT DUE",
  proceedToGasUp: "Proceed to Gas Up",
  proceedToGasUpQuestion: "Proceed to Gas Up?",
  proceedToGasDescription: "Is the Gas up already complete?",
  yesProceed: "Yes, Proceed",
  yesContinue: "Yes, Continue",
  actualGasUpDetails: "Actual Gas Up Details",
  proceedToPayment: "Proceed to Payment",
  proceed: "Proceed",
  proceedwithPaymentQuestion: "Proceed with payment?",
  validateFuelCodeAndVolumeStep: "Validate Fuel Code and Volume",
  actualVolumeDispensed: "Actual volume dispensed",
  processNewFuelCode: "Process New Fuel Code",
  fuelCodeRemainingLimit: "Fuel Code Remaining Limit",
  locqPayForNonFuel: "PLC LOCQPay for Non-Fuel",
  settlementPayee: "Settlement Payee",
  viewAndTrackScanToPayViaLocqPay: "View and track Scan-to-Pay via LOCQPay transactions.",
  locqPayId: "LOCQPay ID",
  selectPaymentMethodHeader: "Select Payment Method to validate payment",
  useEitherFleetCardOrFuelCode: "Use either Fleet Card or Fuel Code",
  fuelCodePayment: "PLB Fuel Code",
  fleetCardPayment: "PLB LOCQPay Fleet Card",
  fleetCardNo: "Fleet Card No.",
  selectPaymentMethod: "Select Payment Method",
  validateFleetCard: "Validate fleet card number and volume to dispense",
  cardBalance: "Card Balance",
  validatePaymentMethod: "Validate Payment Method",
  fleetAccountLocqpayBalance: "Fleet Account LOCQPay Balance",
  fleetCardTransactionCount: "Fleet Card Transaction Count",
  fleetCardRemainingTransactions: "Fleet Card Remaining Transactions",
  fleetCardBalance: "Fleet Card Balance",
  cardInformation: "Card Information",
  cardNo: "Card No.",
  pumpPrice: "Pump Price:",
  computedVolume: "Computed Volume:",
  computedVol: "Computed Volume",
  totalDiscount: "Total Discount",
  actualAmountDispensed: "Actual amount dispensed",
  minimumAllowedRedemption50Peso: "Minimum allowed redemption is 50 peso.",
  minimumAllowedRedemption30Peso: "Minimum allowed redemption is 30 peso.",
  maximumAllowedRedemption50Thousand: "Maximum allowed redemption is 50,000 pesos.",
  selectProductFirst: "Please select a product first before entering amount dispensed.",
  notEnoughBalance: "Not Enough Balance",
  transactionLimitReached: "Transaction Limit Reached",
  yourFleetDoesNotHaveEnoughBalance:
    "Your fleet does not have enough balance to continue with this transaction",
  youAlreadyUsedUpYourFleetCardTransactions: "You already used up your Fleet Card Transactions",
  insufficientBalance:
    "Sorry, you do not have enough Fleet Balance to proceed with this transaction",
  subTotal: "Subtotal",
  totalAmount: "Total Amount",
  maximumAmountAllowedRedemption: "Maximum amount for redemption is {0}",
  fleetCardDeActivated: "Sorry, the fleet card has been deactivated.",
  fleetCardTerminated: "Sorry, the fleet card has been permanently terminated.",
  fleetCardIsInvalid: "Sorry, but we do not recognize this Fleet Card Number.",
  fleetCardNotActiveVehicle:
    "Sorry, the assigned driver or vehicle of this fleet card is not activated. Inform the driver to contact the Account Admin",
  fleetCardNotActiveDriver:
    "Sorry, the assigned driver or vehicle of this fleet card is not activated. Inform the driver to contact the Account Admin",
  YouAlreadyUsedUpRemainingLimit: "You already used up your Fleet Card Remaining Limit.",
  fleetDoesNotHaveEnoughBalance:
    "This fleet account doesn’t have enough balance to continue with this transaction.",
  fleetCardDoesNotBelongToThisStation: "This fleet card can only be redeemed at these {0}.",
  noLimit: "No Limit",
  plbLocqpay: "PLB LOCQPay for Fuel",
  plbLocqpayFleetCard: "PLB LOCQPay Fleet Card",
  locqPayforPLB: "LOCQPay for PLB",
  viewPayWithLOCQPayTransactions: "View Pay with LOCQPay transactions",
  payWithLocqPayID: "Pay with LOCQPay ID",
  gasUpStationWithOutDash: "Gas Up Station",
  cardNumber: "Card Number",
  driversName: `Driver's Name`,
  driversId: "ID",
  discountPerLiter: "Discount Per Liter",
  businessNameId: "Business Name/ID",
  noDriver: "No Available Driver",
  finalAmountPaid: "Final Amount Paid",
  searchDriverIdBusinessNamePlateNumberFleetCardOrNoPaymentId:
    "Driver ID, Business Name, Plate Number, Fleet Card No., OR Number, LOCQPay ID",
  voidingThisTransactionLOCQPay:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance and deduct the previously rewarded {1} rebate from the transaction. Input the reason for voiding below to proceed.",
  voidingThisTransactionSettlementProcessingLOCQPay:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance and deduct the previously rewarded {1} rebate from the transaction. This transaction is already in the process of completing settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed",
  voidingThisTransactionSettlementProcessedLOCPay:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance and deduct the previously rewarded {1} rebate from the transaction. This transaction has already been processed for settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed.",
  purchaseOrderNo: "Purchase Order No.",
  purchaseOrderDetails: "Purchase Order details",
  toCompletePurchaseOrderDescription:
    "To complete the payment process, a Purchase Order No. from the fleet account is needed.",
  enterPurchaseOrderNo: "Enter Purchase Order No.",
  locqPayForFuelID: "LOCQPay for Fuel ID",
  plbAccountIsInSingleInvoicing: " PLB Account is in Single-Invoicing set-up.",
  doNotIssueReceipt: "DO NOT ISSUE A RECEIPT.",
  totalLocqpayDiscount: "Total LOCQPay Discount",
  productNotAvailable: "Product not Available",
  vipAccount: "VIP Account",
  loginVipAccount: "Log In VIP Account",
  step3VipAccount: "Verify VIP Account and select an action",
  vipAccountType: "VIP Account Type",
  accountNumber: "Account number",
  vipRedemptions: "VIP Redemptions",
  payWithPoints: "Pay with Points",
  awardPoints: "Award Points",
  points: "Points",
  scanQRCode: "Scan QR Code",
  enterMobileNumber: "Enter Mobile No.",
  enterVipCardNo: "Enter VIP Card No.",
  mobileNumberNotFound: "Mobile No. not recognized",
  cardNumberNotFound: "Card No. not recognized",
  registerVIPCard: "Register VIP Card",
  transactionHistory: "Transaction History",
  qrCodeRecognized: "QR code recognized.",
  mobileNumberExpired: "Mobile number is already Expired",
  cardNumberExpired: "VIP Card No. is Expired",
  mobileNumberIsInActive: "Mobile number is Inactive",
  cardNumberIsInActive: "VIP Card No. is Deactivated",
  reScan: "Re-scan",
  cardNumberVIP: "ABCDEFGHIJKL",
  cpNumber: "+639000000000",
  vipTransactionId: "VIP Transaction ID",
  vipCardNumber: "VIP Card Number",
  mobileWithHash: "Mobile #",
  transactionAmount: "Transaction Amount",
  pointsEarned: "Points Earned",
  pointsRedeemed: "Points Redeemed",
  rewardsRedeemed: "Rewards Redeemed",
  backToVIPLogin: "Log In VIP Account",
  redeemPoints: "Redeem Points",
  mobileNoIsDeactivated: "Mobile No. is Deactivated",
  cardNumberDeactivated: "Card number is Deactivated",
  commercialIndustrial: "Commercial/Industrial",
  private: "Private",
  public: "Public",
  tnvs: "TNVS",
  sedan: "Sedan",
  suv: "SUV",
  vanmpv: "Van/MPV",
  hatchback: "Hatchback",
  motorcycle: "Motorcycle",
  tricycle: "Tricycle",
  truck: "Truck",
  jeepney: "Jeepney",
  bus: "Bus",
  register: "Register",
  consumerType: "Consumer Type",
  vehicleType: "Vehicle Type",
  registerVIPCardQuestion: "Register VIP Card?",
  registerVIPCardDescrption:
    "You are about to register {0} as {1} with mobile number {2}. Are you sure you want to proceed?",
  mobileNumberAlreadyResgistred: "Mobile number already registered",
  successfulRegisteredVip: "You have successfully registered {0} as {1}",
  errorMessageMobileValidation: "Mobile number is already linked to a VIP Account",
  enterTheOTPSentToMobile: "Enter the OTP sent to mobile number {0}",
  didNotReceiveOTP: "Did not receive OTP?",
  resend: "Resend",
  otpVerification: "OTP Verification",
  vipCardRegistered: "Registration Successful!",
  vipCard: "VIP Card",
  mobileNoIsBlocked: "Mobile No. is Blocked",
  mobileNoIsDeleted: "Mobile No. is Deleted",
  priceLOCQAccountDeactivated: "Linked PriceLOCQ account is deactivated",
  priceLOCQAccountBlocked: "Linked PriceLOCQ account is blocked",
  priceLOCQAccountDeleted: "Linked PriceLOCQ account is deleted",
  cardNumberUnregistered: "VIP Card No. is Unregistered",
  mobileNumberIsNotRecognized: "Mobile No. not recognized",
  virtualCard: "Virtual Card",
  merchantName: "Merchant Name",
  customerName: "Customer Name",
  invalidOTP: "Verification code invalid. Please input correct code.",
  expiredOTP: "This code has expired. Please resend code and try again",
  enteredInvalidOTPFiveTimes:
    "You have entered the code incorrectly 5 times. Please try again after 5 mins.",
  reachedResendLimit:
    "You have reached the limit to receive the code. Please try again after 3 mins.",
  emailAlreadyUsed: "Email address is already registered",
  resendCodeIn: "Resend code in {0} seconds",
  onlyAlphaNumericAllowedAndSpecialV2:
    "Only Alphanumeric characters and the following special symbol ( - , . , ' , spaces ) are allowed.",
  maxPayPoints: "Max. ₱20,000.00",
  minPurchasePayPoints: "Minimum purchase is 1.",
  minPointsToRedeem: "Minimum points to redeem is 1.",
  maxPurchasePayPoints: "You have exceeded maximum purchase.",
  eligibleAmount: "Eligible Amount",
  vipPointsEquivalent: "VIP Points Equivalent",
  giveVIPPoints: "Give VIP Points",
  vipPointsConversion: "VIP Points Conversion",
  awardPointsSuccessMessage: "{0} has been successfully added to {1}'s account with {2}.",
  newPointBalance: "New point Balance:",
  max: "Max",
  fillInTheFieldsBelow: "Fill in the fields below",
  insufficientVIPPointsBalance: "You have insufficient VIP Points balance",
  remainingAmountDue: "Remaining Amount Due:",
  pointsToRedeemShouldBeLessThan:
    "Points to Redeem should be less than or equal to the Purchase Amount",
  paymentSuccessful: "Payment Successful!",
  successfulPaidPointVIP: "You have successfully paid {0} with {1}",
  vipPoints: "VIP Points",
  lubricants: "Lubricants",
  lubes: "lubes",
  fleetCardRemarks: "Fleet Card Remarks",
  remainingPointBalance: "Remaining Point Balance: ",
  stepThree: "Step 3:",
  stepTwo: "Step 2:",
  stepOne: "Step 1:",
  locqpayId: "LOCQPay ID",
  businessNameID: "Business Name/ID",
  gasStationAndBusinessName: "Gas Up Station/ Station Business Name",
  nonFuelTransactions: "Payment History - PLB LOCQPay Non-Fuel",
  plbLOCQPayNonFuel: "PLB LOCQPay for Non-Fuel",
  nonFuelSearch: "Driver ID, Business Name, Plate Number, Fleet Card No., OR Number, LOCQPay ID",
  voidingThisNonFuel:
    "Voiding this transaction will return {0} to the account’s LOCQPay balance and will be excluded in the next settlement cycle. Input the reason for voiding below to proceed.",
  michargedCustomerPaidExcessAmount: "Mischarged: Customer Paid Excess Amount",
  michargedCustomerPaidWrongProduct: "Mischarged: Customer Paid Wrong Product",
  michargedCustomerWasDoubleCharged: "Mischarged: Customer was Double-Charged",
  thisTransactionHasBeenVoidedNonFuel:
    "This transaction has been voided and {0} has been successfully returned to account’s LOCQPay balance.",
  mischargeCustomerWrongAmountInput: "Mischarge: Wrong Amount Input",
  totalAmountName: "totalAmount",
  claimVIPRewards: "Claim VIP Reward",
  selectARewardToRedeem: "Select a reward that you want to claim below.",
  claimRewardsDescription:
    "You are about to convert {0} points to {1} cash for {2}. Are you sure you want to proceed?",
  claimVIPRewardsQuestionMark: "Claim VIP Reward?",
  successfullyVIPRedemption: "You have successfully converted VIP points to cash with {0}.",
  pts: "pts",
  fleetCardManualPo: "PLB LOCQPay - Cardless Prepaid",
  plbFleetCardNo: "Select PLB Account / Fleet Card",
  plbFleetCardNoMessage: "Please select a PLB Account / Fleet Card.",
  pointsRedemption: "Points Redemption",
  fuelCodeType: "Fuel Code Type",
  oneTimeUseOnly: "One-Time Use Only",
  multiUse: "Multi-Use",
  exceedDailyWeeklyMonthly:
    "You have exceeded the {0} points awarding cap, but awarding may still proceed.",
  insufficientBalanceAward: "You have fully exceeded the awarding cap already.",
  vipLoyalty: "VIP Loyalty",
  cardLessPO: "PLB LOCQPay - Cardless Credit",
  creditAccounts: "Credit Accounts",
  creditLimit: "Credit Limit",
  driversCreditLimit: "Fleet Credit Limit",
  fleetAvailableCredit: "Fleet Available Credit",
  latestBillingStatement: "Last Billing Statement",
  dueDate: "Due Date",
  amountPaid: "Amount Paid",
  transact: "Transact",
  statementOfAccount: "Statement of Account",
  gasUp: "Gas Up",
  creditAmountSearch: "Search Business Name",
  dueDateRange: "Due Date Range",
  paid: "Paid",
  unpaid: "Unpaid",
  billingStatement: "Billing Statement",
  seaGas: "SEAGAS",
  statementPeriod: "Statement Period",
  validateAccountLimit: "Validate Account Limit",
  searchInAwardPointsHistory: "Search Mobile Number, VIP Card Number, VIP Transaction ID",
  cash: "Cash",
  viewEarningHistory: "View Earning History",
  mobileNo: "Mobile No.",
  stationOfAwarding: "Station of Awarding",
  earningHistory: "Earning History",
  awardVIPPoints: "Award VIP Points",
  products: "Products",
  vipPayWithPoints: "Pay with Points",
  awardType: "Award Type",
  payWithLiters: "Pay with Liters",
  VIPlocqpay: "LOCQPay",
  priceLOCQ: "PriceLOCQ",
  manualAtStation: "Manual At Station",
  fileNotFound: "File not found.",
};

export default Object.freeze(locale);
