import { AppContext } from "contexts";
import { useContext, useState, useEffect, useRef } from "react";

const useApi = ({ api, params = {}, handleOwnError = false, modalError = true, pageError }) => {
  const { errorModal, setPageError, setErrorStatus } = useContext(AppContext);
  const [loading, setLoading] = useState(undefined);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(undefined);

  // fix: unmount error
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const showError = (err, retry) => {
    if (pageError) {
      setPageError(true);
      setErrorStatus(err.status);
    } else {
      if (modalError) {
        errorModal.show({
          onClick: async () => {
            errorModal.close();
            await retry();
          },
        });
      }
    }
    throw err;
  };

  const request = async (p, retry = () => {}, options = { useLoader: true }) => {
    setPageError(false);
    const { useLoader = true } = options || {};
    if (useLoader) {
      if (isMounted.current) setLoading(true);
    }

    setError(false);
    const obj = { ...params, ...p };
    const cleanedObj = {};

    for (const [key, value] of Object.entries(obj)) {
      if (value !== null && value !== "") {
        cleanedObj[key] = value;
      }
    }

    try {
      const res = await api(cleanedObj);
      if (isMounted.current) {
        setResult(res);
        setLoading(false);
      }
      return res;
    } catch (err) {
      err.showError = () => showError(err, retry);

      if (isMounted.current) {
        setError(true);
        setErrorStatus(err.status);
        setLoading(false);
      }

      if (!err?.status) {
        return showError(err);
      }

      if (err.status === 403 || err.status === 401 || err?.error === "network") {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        return showError(err);
      }

      if (handleOwnError) {
        if (typeof handleOwnError === "boolean") {
          throw err;
        } else if (typeof handleOwnError === "object") {
          if (handleOwnError.hasOwnProperty(err.error)) {
            throw err;
          } else {
            return showError(err);
          }
        }
      } else {
        return showError(err);
      }
    }
  };

  return { request, loading, result, error };
};

export default useApi;
