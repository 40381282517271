import { DateRange, Field, Filter, Button } from "components/commons";
import React from "react";
import styles from "./earning-history-filter.module.scss";
import locale from "localization";

const TransactionHistoryFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.filter}>
      <Filter
        submit={applyFilter}
        clear={applyClearFilter}
        close={applyResetFilter}
        filterCount={filterCount}
      >
        <Field className={styles.date} label={locale.dateOfTransaction} labelPosition="left">
          <DateRange
            className={styles.dateRange}
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyFilter({
                startDate,
                endDate,
              });
            }}
          />
        </Field>
      </Filter>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          disabled={exporting}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default TransactionHistoryFilter;
