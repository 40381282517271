import { useState, useCallback, useMemo, useContext } from "react";
import { Intro, Text, Image, Button, TextField, Field } from "components/commons";
import locale from "localization";
import {
  FleetCardIcon,
  FleetCardSelectedIcon,
  MobileNoIcon,
  MobileNoSelectedIcon,
  VIPScannerIcon,
  VIPScannerSelectedIcon,
  PhilippinesFlag,
  VIPCard,
  TransactionHistory,
  QRCode,
} from "images";
import ValidationCard from "./vip-account-validation-card";
import VIPValidation from "enums/vip-validation";
import styles from "./vip-account.module.scss";
import { initialState as formStateCard } from "./vip-details-card.state";
import { initialState as formStateMobile } from "./vip-details-mobile.state";
import { useForm, useApi, useRouter } from "hooks";
import { handleRequest, redirectTo } from "utils";
import { validateVIP } from "apis";
import { ErrorCode, Path } from "enums";
import VipCardDetails from "./vip-card-details";
import { useMount } from "hooks";
import { StationContext } from "contexts";
import { prettifyVipType } from "utils/pretty.utils";

const VipAccountModule = (props) => {
  const { location } = props;
  const { history } = useRouter();
  const [vipDetails, setVipDetails] = useState(null);
  const [method, setMethod] = useState(null);
  const [stepTwoText, setStepTwoText] = useState(
    "Select a validation method from step 1 before proceeding to this step."
  );

  const { station } = useContext(StationContext);

  if (!station.isVip) {
    redirectTo("/payment/validate");
  }

  const isMethodSelected = useCallback(
    (method) => {
      if (method === stepTwoText) {
        return true;
      } else {
        return false;
      }
    },
    [stepTwoText]
  );

  const [step1Cards] = useState([
    {
      label: locale.scanQRCode,
      icon: VIPScannerIcon,
      iconSelected: VIPScannerSelectedIcon,
      validationMethod: VIPValidation.Scan,
      disabled: true,
    },
    {
      label: locale.enterMobileNumber,
      icon: MobileNoIcon,
      iconSelected: MobileNoSelectedIcon,
      validationMethod: VIPValidation.MobileNum,
      disabled: false,
    },
    {
      label: locale.enterVipCardNo,
      icon: FleetCardIcon,
      iconSelected: FleetCardSelectedIcon,
      validationMethod: VIPValidation.VIPCardNum,
      disabled: false,
    },
  ]);

  const { request: requestValidateVip, loading: loadingValidateVIP } = useApi({
    api: validateVIP,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const form = useMemo(() => {
    let initialState = {};
    if (method === VIPValidation.MobileNum) {
      return formStateMobile(initialState);
    }
    return formStateCard(initialState);
  }, [method]);

  const { fields, modifyField, modifyForm, applyFieldErrors, validatedField } = useForm({
    initialState: form,
  });

  const onChangeCardNumberCb = useCallback(
    (name, value) => {
      modifyField(name, value);

      const cardNumber = value.value;

      const applyCardNumberNoError = (message) => {
        applyFieldErrors(
          {
            cardNumber: message,
          },
          {
            cardNumber: {
              value: cardNumber,
            },
          }
        );
      };
      if (cardNumber && cardNumber.length === 12) {
        handleRequest(
          async () => {
            const res = await requestValidateVip({
              cardNumber,
            });
            if (res) {
              let vipFullName = "Unregistered VIP Card";
              let isRegistered = false;
              let accountType;
              if (res?.user && Object.keys(res?.user).length > 0) {
                vipFullName = `${res?.user?.firstName} ${res?.user?.lastName}`;
                accountType = prettifyVipType(res.user.vipType);
                isRegistered = true;
              }
              setVipDetails({
                ...res,
                vipFullName,
                mobileNumber: res?.user?.mobileNumber,
                accountType,
                isRegistered,
                isMobile: false,
              });
            }
          },
          {},
          async (err) => {
            setVipDetails(null);
            switch (err?.data?.errorCode) {
              case ErrorCode.LoyaltyCardNotFound:
                applyCardNumberNoError(locale.cardNumberNotFound);
                break;
              case ErrorCode.LoyaltyCardIsExpired:
                applyCardNumberNoError(locale.cardNumberExpired);
                break;
              case ErrorCode.LoyaltyCardIsInActive:
                applyCardNumberNoError(locale.cardNumberIsInActive);
                break;
              case ErrorCode.LoyalCardAlreadyLinked:
                applyCardNumberNoError(err?.data?.message);
                break;
              case ErrorCode.LoyalCardUnregistered:
                applyCardNumberNoError(locale.cardNumberUnregistered);
                break;
              case ErrorCode.LoyaltyCardDeleted:
                applyCardNumberNoError(locale.priceLOCQAccountDeleted);
                break;
              case ErrorCode.LoyaltyCardBlocked:
                applyCardNumberNoError(locale.priceLOCQAccountBlocked);
                break;
              case ErrorCode.LoyaltyCardDeactivated:
                applyCardNumberNoError(locale.priceLOCQAccountDeactivated);
                break;
              default:
                applyCardNumberNoError(err?.data?.message);
                return err.showError(true);
            }
          }
        );
      } else {
        setVipDetails(null);
      }
    },
    [modifyField, applyFieldErrors, requestValidateVip]
  );

  useMount(() => {
    if (location?.state?.cardNumber) {
      onChangeCardNumberCb(fields?.cardNumber?.name, { value: location?.state?.cardNumber });
    }

    if (location?.state?.mobileNumber) {
      onChangeMobileNumberCb(fields?.mobileNumber?.name, { value: location?.state?.mobileNumber });
    }
  });

  const onChangeMobileNumberCb = useCallback(
    (name, value) => {
      modifyField(name, value);

      const mobileNumber = value.value;

      const applyMobileNumberNoError = (message) => {
        applyFieldErrors(
          {
            mobileNumber: message,
          },
          {
            mobileNumber: {
              value: mobileNumber,
            },
          }
        );
      };
      if (mobileNumber && mobileNumber.length === 10) {
        handleRequest(
          async () => {
            const res = await requestValidateVip({
              mobileNumber,
            });
            if (res) {
              let vipFullName = "Unregistered VIP Card";
              let isRegistered = false;
              let accountType;
              if (res?.user && Object.keys(res?.user).length > 0) {
                vipFullName = `${res?.user?.firstName} ${res?.user?.lastName}`;
                accountType = prettifyVipType(res.user.vipType);
                isRegistered = true;
              }
              setVipDetails({
                ...res,
                isMobileNumber: true,
                vipFullName,
                mobileNumber: res?.user?.mobileNumber,
                accountType,
                isRegistered,
                isMobile: true,
              });
            }
          },
          {},
          async (err) => {
            setVipDetails(null);
            switch (err?.data?.errorCode) {
              case ErrorCode.LoyaltyCardNotFound:
                applyMobileNumberNoError(locale.mobileNumberIsNotRecognized);
                break;
              case ErrorCode.LoyaltyCardIsExpired:
                applyMobileNumberNoError(locale.mobileNumberExpired);
                break;
              case ErrorCode.LoyaltyCardIsInActive:
                applyMobileNumberNoError(locale.mobileNumberIsInActive);
                break;
              case ErrorCode.LoyalCardAlreadyLinked:
                applyMobileNumberNoError(err?.data?.message);
                break;
              case ErrorCode.LoyaltyCardDeleted:
                applyMobileNumberNoError(locale.mobileNoIsDeleted);
                break;
              case ErrorCode.LoyaltyCardBlocked:
                applyMobileNumberNoError(locale.mobileNoIsBlocked);
                break;
              case ErrorCode.LoyaltyCardDeactivated:
                applyMobileNumberNoError(locale.mobileNoIsDeactivated);
                break;
              case ErrorCode.UserNotFound:
                applyMobileNumberNoError(locale.mobileNumberNotFound);
                break;
              case ErrorCode.LoyalCardUserNotFound:
                applyMobileNumberNoError(locale.mobileNumberNotFound);
                break;
              default:
                applyMobileNumberNoError(err?.data?.message);
                return err.showError(true);
            }
          }
        );
      } else {
        setVipDetails(null);
      }
    },
    [modifyField, applyFieldErrors, requestValidateVip]
  );

  const onSelectValidationMethod = (method) => {
    setMethod(method);
    if (method === VIPValidation.Scan) {
      setStepTwoText(locale.scanQRCode);
      setVipDetails(null);
      modifyForm({
        [fields?.mobileNumber?.name]: {
          value: "",
          required: false,
        },
        [fields?.cardNumber?.name]: {
          value: "",
          required: false,
        },
      });
    } else if (method === VIPValidation.MobileNum) {
      setStepTwoText(locale.enterMobileNumber);
      setVipDetails(null);
      modifyForm({
        [fields?.mobileNumber?.name]: {
          value: "",
          required: true,
        },
        [fields?.cardNumber?.name]: {
          value: "",
          required: false,
        },
      });
    } else if (method === VIPValidation.VIPCardNum) {
      setStepTwoText(locale.enterVipCardNo);
      setVipDetails(null);
      modifyForm({
        [fields?.mobileNumber?.name]: {
          value: "",
          required: false,
        },
        [fields?.cardNumber?.name]: {
          value: location?.state?.cardNumber ? location?.state?.cardNumber : "",
          required: true,
        },
      });
    }
  };

  const disabledPayWithPointsButton = useMemo(() => {
    if (vipDetails) {
      if (vipDetails?.isRegistered) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [vipDetails]);

  const disabledVIPRedemptionsButton = useMemo(() => {
    if (vipDetails) {
      if (vipDetails?.isRegistered) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [vipDetails]);

  const disabledAwardPointsButton = useMemo(() => {
    if (vipDetails) {
      if (vipDetails?.isRegistered) {
        return false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [vipDetails]);

  const cardDetails = {
    loadingValidateVIP,
    vipDetails,
    disabledAwardPointsButton,
    disabledPayWithPointsButton,
    disabledVIPRedemptionsButton,
    hideAwardPointsButton: false,
    hidePayWithPointsButton: false,
    hideVIPRedemptionsButton: false,
    isFromVIPHomePage: true,
  };

  return (
    station.isVip && (
      <div className={styles.container}>
        <div className={styles.leftCardsContainer}>
          <Intro
            style={{ marginLeft: "10px" }}
            title={locale.loginVipAccount}
            actionStartIcon={<Image src={TransactionHistory} />}
            actionText={locale.transactionHistory}
            actionStyle={{ fontWeight: "bold", color: "black", border: "1px solid #afbadd" }}
            actionOnClick={() => {
              history.push(Path.VIPHistoryAwardPoints);
            }}
            actionOutlined={true}
            rounded={false}
          />
          <div className={styles.row}>
            <Text className={styles.title}>{locale.stepOne}</Text>
            <Text className={styles.text}>{" Select a validation method"}</Text>
          </div>
          <div className={styles.rowCard}>
            {step1Cards.map((item) => (
              <ValidationCard
                key={item.label}
                label={item.label}
                icon={item.icon}
                iconSelected={item.iconSelected}
                onClick={() => onSelectValidationMethod(item.validationMethod)}
                isSelected={isMethodSelected(item.label)}
                disabled={item.disabled}
              />
            ))}
          </div>
          <div className={styles.row}>
            <Text className={styles.title}>{locale.stepTwo}</Text>
            <Text className={styles.text}>{stepTwoText}</Text>
          </div>
          {stepTwoText === locale.enterMobileNumber && (
            <div className={styles.inputMobileNumber}>
              <Field className={styles.fieldLabel} {...fields.mobileNumber}>
                <TextField
                  prefix={
                    <div className={styles.flagAndMobileInitial}>
                      <Image src={PhilippinesFlag} className={styles.flagPhilippines} />
                      <Text className={styles.mobileNumberAreaCode}>+63</Text>
                    </div>
                  }
                  {...fields.mobileNumber}
                  onChange={onChangeMobileNumberCb}
                />
              </Field>
            </div>
          )}

          {stepTwoText === locale.scanQRCode && (
            <div className={styles.qrCodeContainer}>
              <div className={styles.qrCode}>
                <Image src={QRCode} alt="QR Code" className={styles.imageQrCode} />
              </div>
              <div className={styles.content}>
                <p>{locale.qrCodeRecognized}</p>
              </div>
              <Button className={styles.reScanButton} onClick={() => {}}>
                {locale.reScan}
              </Button>
            </div>
          )}

          {stepTwoText === locale.enterVipCardNo && (
            <div className={styles.inputMobileNumber}>
              <Field className={styles.fieldLabel} {...fields.cardNumber}>
                <TextField
                  prefix={
                    <div className={styles.flagAndMobileInitial}>
                      <Image src={VIPCard} className={styles.flagPhilippines} />
                    </div>
                  }
                  {...fields.cardNumber}
                  onBlur={validatedField}
                  onChange={onChangeCardNumberCb}
                />
              </Field>
            </div>
          )}
        </div>
        <div className={styles.rightCardsContainer}>
          <div className={styles.rightCardsSection}>
            <div className={styles.stepThree}>
              <Text className={styles.title}>{locale.stepThree}</Text>
              <Text className={styles.text}>{locale.step3VipAccount}</Text>
            </div>
            <VipCardDetails {...cardDetails} />
          </div>
        </div>
      </div>
    )
  );
};

export default VipAccountModule;
