import { Image, Modal, Text, Title, Button, Field, TextField } from "components/commons";
import React, { useMemo, useEffect } from "react";
import { initialState as formState } from "./success-modal-form.state";
import styles from "./success-modal.module.scss";
import locale from "localization";
import { useForm, useApi, useRouter } from "hooks";
import { CarFormSuccess } from "images";
import { updatePayWithPointsByOrNumber } from "apis/vip-points.api";
import { formatAmount } from "utils";
import { Path } from "enums";

const SuccessModalForm = ({
  title,
  content = "",
  image = CarFormSuccess,
  primary,
  secondary,
  contentHighlight = [],
  transactionData,
  amountDue,
  otherData,
  ...state
}) => {
  const history = useRouter();
  const form = useMemo(() => {
    let initialState = {};
    return formState(initialState);
  }, []);

  const { fields, modifyField, submitForm, getFormValues, clearForm, isFormSubmittable } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
    //eslint-disable-next-line
  }, [state.active]);

  const updateAwardPointsRequest = useApi({
    api: updatePayWithPointsByOrNumber,
    handleOwnError: {
      badrequest: true,
    },
  });

  const loading = updateAwardPointsRequest.loading;
  const params = getFormValues();

  const vipDetails = otherData?.isMobile
    ? { mobileNumber: otherData?.mobileNumber?.replace("+63", "") ?? "" }
    : { cardNumber: otherData?.cardNumber ?? "" };

  const submit = async () => {
    let requestUrl = updateAwardPointsRequest;

    try {
      await requestUrl.request({
        pointsHistoryId: transactionData?.pointsHistory?.pointsHistoryId,
        body: { transactionId: params?.transactionId.toString() },
      });
      state.close();
      clearForm();
      history.push({
        pathname: Path.VIPAccount,
        state: { ...vipDetails },
      });
    } catch (error) {
      return error.showError(true);
    }
  };

  const close = () => {
    state.close();
    clearForm();
    history.push({
      pathname: Path.VIPAccount,
      state: { ...vipDetails },
    });
  };

  return (
    <Modal {...state} close={close}>
      <div className={styles.container}>
        <Image src={image} className={styles.image} />
        <Title small className={styles.title}>
          {title}
        </Title>
        <Text className={styles.content}>
          <locale.Populate text={content} items={contentHighlight} />
        </Text>
        <div className={styles.table}>
          <div className={styles.row}>
            <label>{locale.remainingPointBalance}</label>
            <label>
              {transactionData?.remainingPointsBalance ?? 0} {locale.points}
            </label>
          </div>
          <div className={styles.row}>
            <label>{locale.remainingAmountDue}</label>
            <label>{formatAmount(amountDue) ?? 0}</label>
          </div>
          <div className={styles.row}>
            <Field className={styles.fieldContainer} {...fields.transactionId}>
              <TextField
                {...fields.transactionId}
                className={styles.formContainer}
                onChange={modifyField}
              />
            </Field>
          </div>
        </div>
      </div>
      <div className={styles.rowDoneButton}>
        <Button
          primary
          className={styles.doneButton}
          disabled={!isFormSubmittable}
          loading={loading}
          onClick={() => {
            submitForm(submit);
          }}
        >
          {locale.done}
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModalForm;
