import locale from "localization";

export const earningHistoryColumns = [
  { key: "vipTransactionId", text: locale.vipTransactionId, width: "8%" },
  { key: "vipCardNumber", text: locale.vipCardNumber, width: "10%" },
  { key: "mobileNo", text: locale.mobileNumber, width: "6%" },
  { key: "stationOfAwarding", text: locale.stationOfAwarding, width: "10%" },
  { key: "transactionAmount", text: locale.transactionAmount, width: "8%" },
  { key: "productTypeCapitalize", text: locale.productTypeCapitalize, width: "8%" },
  { key: "awardType", text: locale.awardType, width: "6%" },
  { key: "dateOfTransaction", text: locale.dateOfTransaction, width: "8%" },
];
