import { ApiPath } from "enums";
import { Get } from "utils";

export const getCreditAccounts = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.GetCreditAccounts}/${stationId}`, { ...query });
  return res.data;
};

export const getStatementOfAccountApi = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.Billing}/fleet/${fleetId}`, { ...query });
  return res.data;
};

export const getBillingByIdApi = async ({ billingId, ...query }) => {
  const res = await Get(`${ApiPath.Billing}/${billingId}`, { ...query });
  return res.data;
};
