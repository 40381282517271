import { Autocomplete, Field, Modal } from "components/commons";
import locale from "localization";
import styles from "../credit-accounts.module.scss";
import useDataTable from "hooks/useDataTable";
import { mapDataToList, mapFilterToRequest } from "./statement-of-account.mapper";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { getBillingByIdApi, getStatementOfAccountApi } from "apis/credit-account.api";
import { useEffect } from "react";
import { useApi, useModal } from "hooks";
const StatementOfAccountModal = (modal) => {
  const viewSOAModal = useModal();

  const columns = [
    {
      key: "date",
    },
    {
      key: "actions",
    },
  ];

  const initialFilterState = () => {
    return {
      month: "null",
      year: "null",
      status: "billed",
      page: 1,
      perPage: 1000,
    };
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from({ length: 2050 - 2024 + 1 }, (_, index) => (2024 + index).toString());

  const { request: getBillingById } = useApi({
    api: getBillingByIdApi,
  });

  const onClickView = async (billingId, billingDate) => {
    const res = await getBillingById({
      billingId,
    });

    viewSOAModal.show({
      title: billingDate,
      soaUrl: res?.soaUrl,
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getStatementOfAccountApi,
      params: {
        fleetId: modal?.fleetId,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "billings",
      mapper: (billings) =>
        mapDataToList({
          billings,
          onClickView,
        }),
      columns,
    },
  });

  useEffect(() => {
    if (modal?.active) {
      filter?.applyClearFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.active]);

  const SOAFilter = ({ filterState, applyFilter, modifyFilter }) => {
    const onChange = (name, { value }) => {
      filterState[name] = value;
      modifyFilter({ [name]: value, ...filterState });
      if (filterState?.month !== "null" && filterState?.year !== "null") {
        applyFilter?.(filterState);
      }
    };
    return (
      <Field label={locale.statementPeriod} childrenClassName={styles.statementPeriod}>
        <Autocomplete
          name="month"
          value={filterState?.month}
          options={months.map((val, index) => ({ label: val, value: String(index + 1) }))}
          placeholder={locale.month}
          onChange={onChange}
        />
        <Autocomplete
          name="year"
          value={filterState?.year}
          options={years.map((val) => ({ label: val, value: val }))}
          placeholder={locale.year}
          onChange={onChange}
        />
      </Field>
    );
  };
  return (
    <Modal {...modal}>
      <Modal {...viewSOAModal}>
        <embed
          src={viewSOAModal?.soaUrl}
          style={{ width: "50vw", height: "75vh" }}
          type="application/pdf"
        />
      </Modal>
      <SOAFilter {...filter} {...search} />
      <DataTableV2 {...table} paginationV2 hidePagination />
    </Modal>
  );
};

export default StatementOfAccountModal;
