import { Products } from "enums";

export const payHistoryState = () => ({
  startDate: null,
  endDate: null,
  page: 1,
  perPage: 10,
  searchKey: "",
  productCodes: [
    Products.Diesel,
    Products.Gas91,
    Products.Gas95,
    Products.Gas97,
    Products.Lubricants,
    Products.Lubserv,
    Products.Seagas,
  ],
});
