import { Autocomplete, DateRange, Field, Filter, SearchField, Button } from "components/commons";
import { RewardsRedeemed } from "enums";
import React from "react";
import { prettifyRewardsRedeemed } from "utils/pretty.utils";
import styles from "./redeem-history-filter.module.scss";
import locale from "localization";

const RedeemHistoryFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.filter}>
      <Filter
        submit={applyFilter}
        clear={applyClearFilter}
        close={applyResetFilter}
        filterCount={filterCount}
      >
        <Field className={styles.date} label={locale.transactionDate} labelPosition="left">
          <DateRange
            className={styles.dateRange}
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyFilter({
                startDate,
                endDate,
              });
            }}
          />
        </Field>

        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.fieldSelect}
          label={locale.rewardsRedeemed}
        >
          <Autocomplete
            name={"rewardsRedeemed"}
            hasAll
            multiple
            placeholder="Select Rewards"
            value={filterState?.rewardsRedeemed.value}
            options={[
              {
                label: prettifyRewardsRedeemed(RewardsRedeemed.Cash),
                value: RewardsRedeemed.Cash,
              },
            ]}
            onChange={(name, { value, isSelectedAll }) => {
              modifyFilter({ [name]: { value, isSelectedAll } });
            }}
          />
        </Field>
      </Filter>
      <Field className={styles.search}>
        <SearchField
          restrict={false}
          placeholder={[locale.searchInAwardPointsHistory]}
          value={searchKey}
          onChange={(_, { value }) => {
            return modifySearch(value);
          }}
          onEnter={applySearch}
          onClear={applyClearSearch}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          disabled={exporting}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default RedeemHistoryFilter;
