import { prettifyProduct, prettifyAwardCategory } from "utils/pretty.utils";
import { formatAmount } from "utils";
import moment from "moment";
import { DateTime } from "enums";

export const mapDataToList = ({ pointsHistory }) => {
  const {
    pointsHistoryId,
    cardNumber,
    amount,
    productCode,
    mobileNumber,
    station,
    createdAt,
    awardCategory,
  } = pointsHistory;

  return {
    vipTransactionId: <div className="min-60 nowrap">{pointsHistoryId}</div>,
    vipCardNumber: <div>{cardNumber || "-"}</div>,
    mobileNo: <div>{mobileNumber || "-"}</div>,
    stationOfAwarding: <div>{station?.name || "-"}</div>,
    transactionAmount: <div>{formatAmount(amount ?? 0)}</div>,
    productTypeCapitalize: <div>{prettifyProduct(productCode) || "-"}</div>,
    awardType: <div>{prettifyAwardCategory(awardCategory) || "-"}</div>,
    dateOfTransaction: <div className="min-60 nowrap">{moment(createdAt).format(DateTime.L)}</div>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { awardType, ...fs } = filterState;
  return {
    ...fs,

    ...(awardType ? { awardType } : {}),
  };
};
