import React, { useCallback, useImperativeHandle, useRef, forwardRef } from "react";
import { TextField as MaterialTextField } from "@material-ui/core";
import styles from "./textfield.module.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";

const TextField = (
  {
    name,
    type = "text",
    value = "",
    variant = "outlined",
    placeholder = "",
    error,
    suffix,
    onChange,
    onKeyUp,
    onEnter,
    prefix,
    multiline,
    rowsMax,
    disabled,
    maxLength,
    onBlur,
    upperCase,
    onFocus,
    className,
  },
  ref
) => {
  placeholder = typeof placeholder === "string" ? placeholder : "";
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const onChangeCb = useCallback(
    (e) => {
      if (onChange) {
        let value = e?.target.value.toString();
        if (upperCase) {
          value = value.toUpperCase();
        }

        onChange(name, { value });
      }
    },
    [onChange, name, upperCase]
  );

  const onKeyUpCb = useCallback(
    (e) => {
      if (onKeyUp) {
        onKeyUp(e?.target.value.trim(), e?.key);
      }
      if (e.key === "Enter" && onEnter) {
        onEnter(e?.target.value.trim());
      }
    },
    [onKeyUp, onEnter]
  );

  const onBlurCb = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(name, { value: e?.target.value });
      }
    },
    [onBlur, name]
  );

  const onFocusCb = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  return (
    <div className={styles.container}>
      <MaterialTextField
        inputRef={inputRef}
        className={classNames(styles.input, className, {
          [`${styles.multiline}`]: multiline,
          [`${styles.disabled}`]: disabled,
        })}
        error={error}
        type={type}
        value={value}
        variant={variant}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChangeCb}
        onKeyUp={onKeyUpCb}
        multiline={multiline}
        rowsMax={rowsMax}
        onBlur={onBlurCb}
        onFocus={onFocusCb}
        inputProps={{
          maxLength,
        }}
        InputProps={{
          maxLength,
          startAdornment: prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : null,
          endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null,
        }}
      ></MaterialTextField>
    </div>
  );
};

export default forwardRef(TextField);
