import { Intro } from "components/commons";
import { useExport } from "hooks";
import React, { useEffect } from "react";
import EarningHistoryFilter from "./earning-history-filter";
import { earningHistoryState } from "./earning-history-filter.state";
import { getEarningHistory, generateReportLoyalty } from "apis";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { mapDataToList, mapFilterToRequest } from "./earning-history.mapper";
import { earningHistoryColumns } from "./earning-history-columns";
import { SuccessModal } from "components/modals";
const EarningHistoryModule = (props) => {
  const { location, setVipDetails, path, pathTitle } = props;

  const { state } = location;
  const userId = state?.user?.userId;
  const awardType = state?.awardType;

  const { filter, search, table } = useDataTable({
    api: { api: getEarningHistory, params: { userId, ...(awardType ? { awardType } : {}) } },
    filter: { initialState: earningHistoryState(), mapper: mapFilterToRequest },
    table: {
      key: "pointsHistory",
      mapper: (pointsHistory) => mapDataToList({ pointsHistory }),
      columns: earningHistoryColumns,
    },
  });

  const exports = useExport({
    api: generateReportLoyalty,
    reportType: "vip-award-points",
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      userId,
    },
  });

  useEffect(() => {
    setVipDetails({
      ...state,
      path,
      pathTitle,
    });
  }, [setVipDetails, state, path, pathTitle]);

  return (
    <div>
      <div>
        <Intro />
      </div>
      <EarningHistoryFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
      <SuccessModal {...exports?.successModalComponent} />
    </div>
  );
};

export default EarningHistoryModule;
