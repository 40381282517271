import React, { useContext } from "react";

import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import { useHistory } from "react-router-dom";
import { mapFilterToRequest, mapDataToList } from "./credit-accounts.mapper";
import { columns } from "./credit-accounts-column";
import { initialFilterState } from "./credit-accounts-filter.state";
import { useExport, useModal } from "hooks";
import CreditAccountsFilter from "./credit-accounts-filter";
import { getCreditAccounts } from "apis/credit-account.api";
import { generateStationReport } from "apis";
import CreditAccountsForm from "./credit-accounts-form";
import { ConfirmModal, SuccessModal } from "components/modals";
import { StationContext } from "contexts";
import { Path } from "enums";
import StatementOfAccountModal from "./statement-of-account-modal/statement-of-account.modal";

const CreditAccountsModule = () => {
  const { station } = useContext(StationContext);
  const history = useHistory();
  const { stationId } = station;
  const confirmModal = useModal();

  const viewSOAModal = useModal();

  const handleGoToValidatePayment = () => {
    history.push(Path.ValidatePayment);
  };

  const onClickSOAView = (fleetId) => {
    viewSOAModal.show({
      title: locale.statementOfAccount,
      fleetId,
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getCreditAccounts,
      params: {
        stationId,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "billings",
      mapper: (billings) =>
        mapDataToList({
          billings,
          handleGoToValidatePayment,
          onClickSOAView,
        }),
      columns,
    },
  });

  const creditAccountsFormModal = useModal();

  const exports = useExport({
    api: generateStationReport,
    reportType: "billing",
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      redemptionStationId: stationId,
      stationId,
      searchKey: search?.searchKey,
    },
  });

  return (
    <>
      <ConfirmModal {...confirmModal} />
      <StatementOfAccountModal {...viewSOAModal} />
      <CreditAccountsForm
        {...creditAccountsFormModal}
        refreshPage={(isEdit) => {
          table.fetch({});
          if (isEdit) {
            creditAccountsFormModal.close();
          }
        }}
      />
      <SuccessModal {...exports?.successModalComponent} />
      <Intro title={locale.creditAccounts} />
      <CreditAccountsFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2 />
    </>
  );
};

export default CreditAccountsModule;
