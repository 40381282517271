import { Intro } from "components/commons";
import { StationContext } from "contexts";
import { useExport } from "hooks";
import React, { useContext } from "react";
import PayHistoryFilter from "./pay-history-filter";
import { payHistoryState } from "./pay-history-filter.state";
import { getTransactionHistoryPayWithPoints, generateStationReportLoyalty } from "apis";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { mapDataToList, mapFilterToRequest } from "./pay-history.mapper";
import { payHistoryColumns } from "./pay-history-columns";
import { SuccessModal } from "components/modals";

const PayHistoryModule = ({ title }) => {
  const { station } = useContext(StationContext);
  const { stationId } = station;

  const { filter, search, table } = useDataTable({
    api: { api: getTransactionHistoryPayWithPoints, params: { stationId } },
    filter: { initialState: payHistoryState(), mapper: mapFilterToRequest },
    table: {
      key: "payHistory",
      mapper: (payHistory) => mapDataToList({ payHistory }),
      columns: payHistoryColumns,
    },
  });

  const exports = useExport({
    api: generateStationReportLoyalty,
    reportType: "vip-pay-with-points",
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      stationId,
    },
  });

  return (
    <div>
      <div>
        <Intro />
      </div>
      <PayHistoryFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
      <SuccessModal {...exports?.successModalComponent} />
    </div>
  );
};

export default PayHistoryModule;
